.lost-password-section {
    display: flex;
    align-items: center;
    height: 100vh;
    background-image: url("../img/bg.png");
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            180deg,
            rgba(9, 184, 108, 0.5) 0,
            rgba(10, 194, 62, 0.425) 50%,
            rgba(0, 170, 1, 0.5) 100%
        );
        mix-blend-mode: color;
        z-index: 1;
    }
    .container {
        position: relative;
        z-index: 2;
        margin: auto;
        width: 540px;
        max-width: calc(100% - 60px);
        h1 {
            text-align: center;
        }
        form {
            border-radius: 8px;
            border: 1px solid #e7e7e7;
            padding: 25px;
            box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
            background-color: white;
        }
        button {
            &[type="submit"] {
                background-color: #00aa01;
                border: none;
            }
        }
    }
}
